.bg {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/background.jpg");
}

.login-card {
  padding: 3em;
  width: 28em;
  min-height: 20em;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: space-around;
}
